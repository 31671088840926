import ServerURL from "../server/serverUrl";

export const APIRoutes = {
    //Login Api
    APP_LOGIN_USER: `${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/LoginSuccessBranch`,
    APP_COMPANY_ADDRESS:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/SelectCompanyWeb`,
    APP_COMPANY_ADMIN:`${ServerURL.PRODUCTION_HOST_URL}/api/BranchApp/SelectCompanyAdmin`,
    APP_DASHBOARD:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/Dashboard`,
    APP_RECENTORDER:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/SaleOrderView`,
    APP_SELECTCOMPANYADMIN:`${ServerURL.PRODUCTION_HOST_URL}/api/BranchApp/SelectCompanyAdmin`,
    APP_INSERTCOMPANYADMIN:`${ServerURL.PRODUCTION_HOST_URL}/api/BranchApp/InsertBranchMaster`,
    APP_SELECTAREA:`${ServerURL.PRODUCTION_HOST_URL}/api/AreaEcomApp/SelectAreaMaster`,
    APP_DELETEAREA:`${ServerURL.PRODUCTION_HOST_URL}/api/AreaEcomApp/DeleteAreaMaster`,
    APP_INSERTAREA:`${ServerURL.PRODUCTION_HOST_URL}api/AreaEcomApp/InsertAreaMaster`,
    APP_SELECTDELIVERYTIME:`${ServerURL.PRODUCTION_HOST_URL}/api/DeliveryChargesApp/SelectDeliveryTime`, 
    APP_DELETEDELIVERYTIME:`${ServerURL.PRODUCTION_HOST_URL}/api/DeliveryChargesApp/DeleteDeliveryTime`, 
    APP_INSERTDELIVERYTIME:`${ServerURL.PRODUCTION_HOST_URL}/api/DeliveryChargesApp/InsertDeliveryTime`, 
    APP_SELECTOFFERPOST:`${ServerURL.PRODUCTION_HOST_URL}/api/OfferPost/SelectOfferPost`, 
    APP_DELETEOFFERPOST:`${ServerURL.PRODUCTION_HOST_URL}/api/OfferPost/DeleteOfferPost`, 
    APP_INSERTOFFERPOST:`${ServerURL.PRODUCTION_HOST_URL}/api/OfferPost/InsertOfferPost`,
    APP_SELECTBANNERPSOST:`${ServerURL.PRODUCTION_HOST_URL}/api/CategoryEomApp/SelectCategory`, 
    APP_DELETESUBCATEGORY:`${ServerURL.PRODUCTION_HOST_URL}/api/SubCategoryApp/DeleteSubCategory`, 
    APP_DELETEBANNERPOST:`${ServerURL.PRODUCTION_HOST_URL}/api/CategoryEcomApp/DeleteCategory`, 
    APP_APP_SELECTCATEGORY:`${ServerURL.PRODUCTION_HOST_URL}/api/CategoryEcomApp/SelectCategory`, 
    APP_UPDATEREORDER:`${ServerURL.PRODUCTION_HOST_URL}/api/CategoryEcomApp/UpdateCategorySorting`, 
    APP_SELECTSUBCATEGORY:`${ServerURL.PRODUCTION_HOST_URL}/api/SubCategoryApp/SelectSubCategory`, 
    APP_SELECTPRODUCT:`${ServerURL.PRODUCTION_HOST_URL}/api/ItemmasterEcomApp/SelectProductAdminSearch`, 
    APP_DELETEPRODUCT:`${ServerURL.PRODUCTION_HOST_URL}/api/ItemmasterEcomApp/DeleteItemMaster`, 
    APP_SELECTSELEORDER:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/SaleOrderView`, 
    APP_SELECTCUSTOMER:`${ServerURL.PRODUCTION_HOST_URL}/api/CustomerApp/SelectCustomer`, 
    APP_SELECTUPDATEORDER:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/UpdateSaleOrder`,
    APP_SELECTSERVICES:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp//SelectSettingsNew1`,
    APP_UPDATESERVICES:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/InsertSettings`,
    APP_UPLOADIMAGES:`${ServerURL.PRODUCTION_HOST_URL}/api/OfferPost/UploadOfferPostFile`,
    APP_INSERTBANNER:`${ServerURL.PRODUCTION_HOST_URL}/api/CategoryEcomApp/InsertBanner`,
    APP_INSERTCATEGORY:`${ServerURL.PRODUCTION_HOST_URL}/api/CategoryEcomApp/InsertCategory`,
    APP_INSERTSUBCATEGORY:`${ServerURL.PRODUCTION_HOST_URL}/api/SubCategoryApp/InsertSubCategory`,
    APP_SENDNOTIFICATION:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/OfferNotification`,
    APP_SELECTSUBCATEGORYID:`${ServerURL.PRODUCTION_HOST_URL}/api/ItemmasterEcomApp/SelectSubCategorId`,
   // APP_SELECTSUBCATEGORY:`${ServerURL.PRODUCTION_HOST_URL}/api/ItemmasterApp/SelectSubCategorId`,
    APP_SELECTMULTIPLEPRICELISTNEW:`${ServerURL.PRODUCTION_HOST_URL}/api/ItemmasterEcomApp/SelectMultiplePriceListCheck`,
    APP_SELECTPRODUCTIDADMIN:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/SelectProductIdAdmin`,
    APP_INSERTPRODUCT:`${ServerURL.PRODUCTION_HOST_URL}/api/ItemMasterEcomApp/InsertItemMaster`,
    APP_INSERTCOUPON:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/InsertCoupon`,
    APP_INSERTDELIVERYAREA:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/InsertDeliveryArea`,
    APP_INSERTBRAND:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/InsertBrand`,
    APP_SELECTBRAND:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/SelectBrand`,
    APP_SELECTCOUPON:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/SelectCoupon`,
    APP_SELECTDElIVERYAREA:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/SelectDeliveryArea`,
    APP_DELETECOUPON:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/DeleteCoupon`,
    APP_DELETEAREA:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/DeleteArea`,
    APP_DELETEBRAND:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/DeleteBrand`,
    APP_UPDATECUSTOMER:`${ServerURL.PRODUCTION_HOST_URL}/api/WebMobileApp/UpdateCustomerProfile`,
};

export default APIRoutes;
